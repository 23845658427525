<!-- ----------------------------------------------------------------------- -->
<!--
name    : FLAT RATE LIST

type    : component

used by : AppCatalogList

uses    : table-status
          work-category-create-dialog

route   : none
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="flat-rate-list">
    <v-container>
      <v-toolbar flat color="white" class="mt-1">
        <v-toolbar-title>
          <span class="headline"> Flat Rate Categories </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <v-btn @click="addWorkCategory()" class="button-primary px-2">
            <v-icon>mdi-plus</v-icon>
            NEW
          </v-btn>
        </v-toolbar-title>
      </v-toolbar>
      <div>
        <v-data-table
          :headers="headers"
          :items="work_categories"
          class="elevation-1"
          sort-by="name">
          <template v-slot:item="props">
            <tr
              @click="
                $router.push({
                  name: 'WorkCategoryView',
                  params: {uuid: props.item.uuid},
                })
              ">
              <td>{{ props.item.name }}</td>
              <td>
                {{ formatCurrency(props.item.default_rate, currencySymbol) }}
              </td>
              <td class="text-center">
                <table-status :status="props.item.status" />
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            There are currently no categories, click the NEW button to create
            one.
          </template>
        </v-data-table>
      </div>
    </v-container>
    <work-category-create-dialog v-model="createDialog" catalog="FLAT" />
  </div>
</template>

<script>
  // components
  import WorkCategoryCreateDialog from '@/components/catalog/workcategory/work-category-create-dialog';
  import TableStatus from '@/components/table-status';

  // mixins
  import Localization from '@/mixins/localization';

  // services
  import Users from '@/services/Users.service.js';

  export default {
    name: 'FlatRateList',
    components: {
      'table-status': TableStatus,
      'work-category-create-dialog': WorkCategoryCreateDialog,
    },
    mixins: [Localization],
    props: {},
    data() {
      return {
        work_categories: [],
        createDialog: false,
        headers: [
          {
            text: 'Name',
            align: 'left',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Default Rate',
            value: 'default_rate',
          },
          {
            text: 'Status',
            value: 'status',
            align: 'center',
          },
        ],
        tenant_uuid: this.$auth.userProfile.tenant_uuid,
      };
    },
    computed: {
      currencySymbol() {
        var symbol = this.$auth.tenantProfile.currency_symbol
          ? this.$auth.tenantProfile.currency_symbol
          : '$';
        return symbol;
      },
    },
    async mounted() {
      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      // Get FLAT work category list
      this.work_categories = await Users.getWorkCategories(
        {
          tenant_uuid: this.tenant_uuid,
          catalog: 'FLAT',
        },
        accessToken
      );
    },
    methods: {
      addWorkCategory() {
        this.createDialog = false;
        this.createDialog = true;
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
  #filter-select {
    height: 60px;
    width: 100px;
    margin: auto;
  }
</style>
