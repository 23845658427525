import { render, staticRenderFns } from "./flat-rate-list.vue?vue&type=template&id=b5a5dfd2&scoped=true&"
import script from "./flat-rate-list.vue?vue&type=script&lang=js&"
export * from "./flat-rate-list.vue?vue&type=script&lang=js&"
import style0 from "./flat-rate-list.vue?vue&type=style&index=0&id=b5a5dfd2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5a5dfd2",
  null
  
)

export default component.exports