<!-- ----------------------------------------------------------------------- -->
<!--
name    : WORK CATEGORY CREATE

type    : component

used by : flat-rate-list,
          hourly-rate-list,
          variable-rate-list

uses    : header-view
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="work-category-create">
    <v-layout row justify-center>
      <v-form ref="form" @submit.prevent="save" class="ma-2">
        <v-dialog persistent v-model="dialog" width="500">
          <v-card color="grey lighten-4" min-width="350px" flat>
            <header-view
              dialog
              previous_page="NO_BACK_BUTTON"
              :title="
                catalog == 'HOURLY'
                  ? 'Hourly Rate Category'
                  : catalog == 'VARIABLE'
                  ? 'Variable Rate Category'
                  : 'Flat Rate Category'
              "
              closeBtn
              :closeAction="cancel"
              :btnOptions="[
                {
                  name: '',
                  btnColor: 'white',
                  icon: 'mdi-check',
                  action: this.save,
                },
              ]" />

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md4>
                    <v-text-field
                      label="Name*"
                      v-model="workCategory.name"
                      hint=""
                      required
                      :rules="validate_required"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md4 v-show="showDefaultRate">
                    <v-text-field
                      label="Default Rate"
                      v-model="workCategory.default_rate"
                      :key="updateDefaultFormat"
                      hint=""
                      required
                      :rules="validate_money_amount_0"
                      :prefix="currencySymbol"
                      @blur="formatRate()"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md4 v-show="showSizeAttribute">
                    <v-select
                      id="pricing_method"
                      v-model="workCategory.pricing_method"
                      :items="sizingSelects"
                      label="Pricing Method"></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md4>
                    <v-select
                      disabled
                      id="status"
                      v-model="workCategory.status"
                      :items="statusSelects"
                      label="Active**"></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      label="Description"
                      v-model="workCategory.description"></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
              <div style="display: inline-flex; flex-direction: column">
                <small>*indicates required field</small>
                <small
                  >**category can be activated once subtasks are added</small
                >
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-form>
    </v-layout>
    <v-snackbar v-model="snackbar" :timeout="5000">
      Record has been saved
      <v-btn class="button-primary" text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  // mixins
  import Localization from '@/mixins/localization';
  import Forms from '@/mixins/forms.js';

  // components
  import HeaderView from '@/components/header-view';

  // services
  import Users from '@/services/Users.service.js';

  export default {
    name: 'WorkCategoryCreateDialog',
    components: {
      'header-view': HeaderView,
    },
    mixins: [Forms, Localization],
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      catalog: {
        type: String,
        default: 'PHC',
      },
      default_rate: {
        type: String,
        default: '0',
      },
    },
    data() {
      return {
        snackbar: false,
        dialog: false,
        statusSelects: [
          {
            text: 'Active',
            value: 'Active',
          },
          {
            text: 'Inactive',
            value: 'Inactive',
          },
        ],
        sizingSelects: [
          {
            text: 'DBH',
            value: 'DBH',
          },
          {
            text: 'Height',
            value: 'HEIGHT',
          },
        ],
        updateDefaultFormat: 0,
        workCategory: {},
        tenant_uuid: this.$auth.userProfile.tenant_uuid,
      };
    },
    computed: {
      currencySymbol() {
        var symbol = this.$auth.tenantProfile.currency_symbol
          ? this.$auth.tenantProfile.currency_symbol
          : '$';
        return symbol;
      },
      showDefaultRate: function () {
        if (this.catalog != 'VARIABLE') {
          return true;
        }
        return false;
      },
      showSizeAttribute: function () {
        if (this.catalog == 'VARIABLE') {
          return true;
        }
        return false;
      },
    },
    watch: {
      value: function () {
        if (this.dialog != this.value) {
          // console.log( "value updated externally" );
          this.dialog = this.value;
        }
      },
      dialog: function (before, after) {
        if (before != after) {
          // console.log( "value updated internally" );
          this.$emit('input', this.dialog);
        }
      },
      default_rate: function () {
        this.workCategory.default_rate = this.default_rate;
        this.formatRate();
      },
    },
    created() {
      this.workCategory = Users.blankWorkCategory();
      this.workCategory.tenant_uuid = this.tenant_uuid;
      // console.log( "workcategory created" );
      this.workCategory.default_rate = this.default_rate;
      this.workCategory.status = 'Inactive';
      if (this.catalog == 'FLAT') {
        this.workCategory.pricing_method = 'FLAT';
      } else if (this.catalog == 'HOURLY') {
        this.workCategory.pricing_method = 'HOURLY';
      } else if (this.catalog == 'VARIABLE') {
        this.workCategory.pricing_method = 'DBH';
      }
    },
    mounted() {
      // console.log( "workcategory mounted" );
      //set initial dialog status
      this.dialog = this.value;

      //set initial workcategory fields
      if (this.catalog == 'FLAT') {
        this.pricing_method = 'FLAT';
      } else if (this.catalog == 'VARIABLE') {
        this.pricing_method = 'DBH';
      } else if (this.catalog == 'HOURLY') {
        this.pricing_method = 'HOURLY';
      }
    },
    methods: {
      async save() {
        if (!this.$refs.form.validate()) {
          return false;
        }

        // assign current catalog value (flat)
        this.workCategory.catalog = this.catalog;

        // create new work category through api
        var res = await Users.createWorkCategory(
          this.workCategory
          // unsecured route due to onboarding, no accesstoken
        );

        if (res) {
          this.dialog = false;
          this.snackbar = true;

          this.$router.push({
            name: 'WorkCategoryView',
            params: {
              uuid: this.workCategory.uuid,
            },
          });
        } else {
          console.log('could not save new work category');
        }
      },
      async cancel() {
        //reset
        this.workCategory.default_rate = this.default_rate;
        this.workCategory.status = 'Inactive';
        this.workCategory.name = '';
        this.workCategory.description = '';
        if (this.catalog == 'FLAT') {
          this.workCategory.pricing_method = 'FLAT';
        } else if (this.catalog == 'HOURLY') {
          this.workCategory.pricing_method = 'HOURLY';
        } else if (this.catalog == 'VARIABLE') {
          this.workCategory.pricing_method = 'DBH';
        }
        this.dialog = false;
      },
      formatRate() {
        var prev = this.workCategory.default_rate;
        this.workCategory.default_rate = parseFloat(
          this.workCategory.default_rate
        ).toFixed(2);
        this.updateDefaultFormat = prev;
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped></style>
