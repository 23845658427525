<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP CATALOG LIST

type    : view

used by : none

uses    : hourly-rate-list
          variable-rate-list
          flat-rate-list

route   : /catalog (default route)
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <v-tabs fixed-tabs>
    <v-tab key="hourlyrate"> Hourly Rate </v-tab>
    <v-tab key="variablerate"> Variable Rate </v-tab>
    <v-tab key="flatrate"> Flat Rate </v-tab>
    <v-tab-item key="hourlyrate">
      <hourly-rate-list />
    </v-tab-item>
    <v-tab-item key="variablerate">
      <variable-rate-list />
    </v-tab-item>
    <v-tab-item key="flatrate">
      <flat-rate-list />
    </v-tab-item>
  </v-tabs>
</template>

<script>
  import HourlyRateList from '@/components/catalog/hourly-rate-list';
  import VariableRateList from '@/components/catalog/variable-rate-list';
  import FlatRateList from '@/components/catalog/flat-rate-list';

  export default {
    name: 'AppCatalogList',
    components: {
      'hourly-rate-list': HourlyRateList,
      'variable-rate-list': VariableRateList,
      'flat-rate-list': FlatRateList,
    },
    props: {},
    data() {
      return {};
    },
    computed: {},
    mounted() {},
    methods: {},
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped></style>
