<!-- ----------------------------------------------------------------------- -->
<!--
name    : HOURLY RATE LIST

type    : component

used by : AppCatalogList

uses    : table-status
          work-category-create-dialog
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="hourly-rate-list">
    <v-container>
      <v-toolbar flat color="white" class="mt-1">
        <v-toolbar-title>
          <span class="headline"> Hourly Rate Categories </span>
        </v-toolbar-title>
        <v-spacer />
        <!--span class="subtitle-2 mr-1">
          Default Hourly Rate:
        </span-->
        <v-text-field
          v-if="editRate"
          v-model="tenant.default_hourly_rate"
          suffix="Default Hourly Rate: "
          class="default-hourly-rate mt-5 mr-2"
          dense
          reverse
          :rules="validate_money_amount_0" />
        <v-text-field
          v-if="!editRate"
          v-model="fancy_default_rate"
          suffix="Default Hourly Rate: "
          class="default-hourly-rate mt-5 mr-2"
          dense
          reverse
          readonly />
        <v-btn
          v-if="editRate"
          class="px-2"
          color="secondary"
          icon
          tile
          :ripple="false"
          x-small
          @click="
            saveTenant();
            editRate = false;
          ">
          <v-icon color="secondary">mdi-check</v-icon>
        </v-btn>
        <v-btn
          v-if="!editRate"
          class="px-2"
          color="secondary"
          icon
          tile
          :ripple="false"
          x-small
          @click="editRate = true">
          <v-icon color="secondary">mdi-pencil</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn class="button-primary px-2" @click="addWorkCategory()">
          <v-icon>mdi-plus</v-icon>
          NEW
        </v-btn>
      </v-toolbar>
      <div>
        <v-data-table
          :headers="headers"
          :items="work_categories"
          class="elevation-1"
          sort-by="name">
          <template v-slot:item="props">
            <tr
              @click="
                $router.push({
                  name: 'WorkCategoryView',
                  params: {uuid: props.item.uuid},
                })
              ">
              <td>{{ props.item.name }}</td>
              <td>
                {{ formatCurrency(props.item.default_rate, currencySymbol) }}
              </td>
              <td class="text-center">
                <table-status :status="props.item.status" />
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            There are currently no categories, click the NEW button to create
            one.
          </template>
        </v-data-table>
      </div>
    </v-container>
    <work-category-create-dialog
      v-model="createDialog"
      catalog="HOURLY"
      :default_rate="tenant.default_hourly_rate" />
  </div>
</template>

<script>
  // components
  import WorkCategoryCreateDialog from '@/components/catalog/workcategory/work-category-create-dialog';
  import TableStatus from '@/components/table-status';

  // mixins
  import Forms from '@/mixins/forms.js';
  import Localization from '@/mixins/localization';

  // services
  import Users from '@/services/Users.service.js';
  import Tenants from '@/services/Tenants.service.js';

  export default {
    name: 'HourlyRateList',
    components: {
      'table-status': TableStatus,
      'work-category-create-dialog': WorkCategoryCreateDialog,
    },
    mixins: [Forms, Localization],
    props: {},
    data() {
      return {
        work_categories: [],
        createDialog: false,
        headers: [
          {
            text: 'Name',
            align: 'left',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Default Rate',
            value: 'default_rate',
          },
          {
            text: 'Status',
            value: 'status',
            align: 'center',
          },
        ],
        editRate: false,
        tenant_uuid: this.$auth.userProfile.tenant_uuid,
        tenant: {},
      };
    },
    computed: {
      currencySymbol() {
        var symbol = this.$auth.tenantProfile.currency_symbol
          ? this.$auth.tenantProfile.currency_symbol
          : '$';
        return symbol;
      },
      fancy_default_rate: function () {
        return this.formatCurrency(
          this.tenant.default_hourly_rate,
          this.currencySymbol
        );
      },
    },
    async mounted() {
      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      // Get tenant (for updating default hourly rate)
      this.tenant = await Tenants.getTenant(this.tenant_uuid, accessToken);

      // Get FLAT work category list
      this.work_categories = await Users.getWorkCategories(
        {
          tenant_uuid: this.tenant_uuid,
          catalog: 'HOURLY',
        },
        accessToken
      );
    },
    methods: {
      addWorkCategory() {
        this.createDialog = false;
        this.createDialog = true;
      },
      async saveTenant() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // Update tenant default hourly rate
        await Tenants.updateTenant(this.tenant_uuid, this.tenant, accessToken);
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
  #filter-select {
    height: 60px;
    width: 100px;
    margin: auto;
  }

  .default-hourly-rate {
    max-width: 225px !important;
    min-width: 225px !important;
  }
</style>
