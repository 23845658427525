<!-- ----------------------------------------------------------------------- -->
<!--
name    : VARIABLE RATE LIST

type    : component

used by : AppCatalogList

uses    : table-status
          work-category-create-dialog
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="variable-rate-list">
    <v-container>
      <v-layout>
        <v-flex xs12>
          <v-toolbar flat color="white" class="mt-1">
            <v-toolbar-title class="headline">
              Variable Rate Categories
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-title>
              <v-btn class="button-primary px-2" @click="addWorkCategory()">
                <v-icon>mdi-plus</v-icon>
                NEW
              </v-btn>
            </v-toolbar-title>
          </v-toolbar>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex xs12>
          <v-data-table
            :headers="headers"
            :items="work_categories"
            class="elevation-1"
            sort-by="name">
            <template v-slot:item="props">
              <tr
                @click="
                  $router.push({
                    name: 'WorkCategoryView',
                    params: {uuid: props.item.uuid},
                  })
                ">
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.pricing_method }}</td>
                <td class="text-center">
                  <table-status :status="props.item.status" />
                </td>
              </tr>
            </template>
            <template v-slot:no-data>
              There are currently no pricing groups in this catalog, click the
              NEW button to create one.
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
    <work-category-create-dialog v-model="createDialog" catalog="VARIABLE" />
  </div>
</template>

<script>
  // components
  import WorkCategoryCreateDialog from '@/components/catalog/workcategory/work-category-create-dialog';
  import TableStatus from '@/components/table-status';

  // services
  import Users from '@/services/Users.service.js';

  export default {
    name: 'PhcList',
    components: {
      'table-status': TableStatus,
      'work-category-create-dialog': WorkCategoryCreateDialog,
    },
    mixins: [],
    props: {},
    data() {
      return {
        work_categories: [],
        createDialog: false,
        headers: [
          {
            text: 'Name',
            align: 'left',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Size Attribute',
            value: 'pricing_method',
          },
          {
            text: 'Status',
            value: 'status',
            align: 'center',
          },
        ],
        tenant_uuid: this.$auth.userProfile.tenant_uuid,
      };
    },
    async mounted() {
      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      // Get VARIABLE work category list
      this.work_categories = await Users.getWorkCategories(
        {
          tenant_uuid: this.tenant_uuid,
          catalog: 'VARIABLE',
        },
        accessToken
      );
    },
    methods: {
      addWorkCategory() {
        this.createDialog = true;
      },
      viewCategory(uuid) {
        this.$router.push('/workcategories/view/' + uuid);
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
  #filter-select {
    height: 60px;
    width: 100px;
    margin: auto;
  }
</style>
